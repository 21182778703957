import axiosIns from '@axiosIns'

export default {
  namespaced: true,
  state: {
    start: '',
    end: '',
    tab: 'Calls',
  },
  getters: {
    getPeriod: state => ({ start: state.start, end: state.end }),
    getTab: state => state.tab,
  },
  mutations: {
    setStart(state, val) {
      state.start = val
    },
    setEnd(state, val) {
      state.end = val
    },
    setTab(state, val) {
      state.tab = val
    },
  },
  actions: {
    setStartDate({ commit }, val) {
      commit('setStart', val)
    },
    setEndDate({ commit }, val) {
        commit('setEnd', val)
    },
    setTab({ commit }, val) {
      commit('setTab', val)
    },
    fetchStatisticRoute(ctx, {
        demandType,
      }) {
      const headers = {
        'Content-Type': 'application/json',
      }
      const { start, end, tab: route } = ctx.state
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`Statistics/${route}`,
            { end, start, demandType: demandType || 0 },
            {
              headers,
            })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
