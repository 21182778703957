import axiosIns from '@/libs/axiosIns'

export default {
  namespaced: true,
  state: {
    events: [],
    eventYoungAnonymize: {},
    eventYoungExtractDatas: {},
    eventYoungTransferData: {},
  },
  getters: {
    events: state => state.events,
    eventYoungAnonymize: state => state.eventYoungAnonymize,
    eventYoungExtractDatas: state => state.eventYoungExtractDatas,
    eventYoungTransferData: state => state.eventYoungTransferData,
  },
  mutations: {
    fetchEvents(state, events) {
      state.events = events
    },
    fetchEventYoungAnonymize(state, eventYoungAnonymize) {
      state.eventYoungAnonymize = eventYoungAnonymize
    },
    fetchEventYoungExtractDatas(state, eventYoungExtractDatas) {
      state.eventYoungExtractDatas = eventYoungExtractDatas
    },
    fetchEventYoungTransferData(state, eventYoungTransferData) {
      state.eventYoungTransferData = eventYoungTransferData
    },
    addEventMutation(state, event) {
      state.events.push(event)
    },
  },
  actions: {
    async fetchEventsAction({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/ApplicationEvent')
          .then(response => {
            commit('fetchEvents', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchEventYoungAnonymize({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`ApplicationEvent/GetApplicationEventByYoungIdAndEventType/${data.youngId}/5`)
          .then(response => {
            commit('fetchEventYoungAnonymize', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchEventYoungExtractDatas({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`ApplicationEvent/GetApplicationEventByYoungIdAndEventType/${data.youngId}/8`)
          .then(response => {
            commit('fetchEventYoungExtractDatas', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchEventYoungTransferData({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`ApplicationEvent/GetApplicationEventByYoungIdAndEventType/${data.youngId}/6`)
          .then(response => {
            commit('fetchEventYoungTransferData', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
