import axiosIns from '@axiosIns'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    events: [],
    usersAvailable: [],
  },
  getters: {
    events: (state, getters, rootState, rootGetters) => state.events.map(event => ({
      ...event,
      endDate: new Date(event.end),
      startDate: new Date(event.start),
      title: rootGetters['users/getUserFullName'](event.userId),
    })),
    getEvent: (state, getters) => id => getters.events.find(event => event.id === id),
    usersAvailable: state => state.usersAvailable,
  },
  mutations: {
    addEvent(state, event) {
      state.events.push(event)
    },
    deleteEvent(state, id) {
      state.events = state.events.filter(e => e.id !== id)
    },
    updateEvent(state, event) {
      const index = state.events.findIndex(e => e.id === event.id)
      Vue.set(state.events, index, event)
    },
    setEvents(state, events) {
      state.events = events
    },
    setAvailableUsers(state, users) {
      state.usersAvailable = users
    },
  },
  actions: {
    fetchEvents(ctx, { start, end }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('Event', { params: { start, end } })
          .then(response => {
            ctx.commit('setEvents', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUsersAvailable({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('Event/AvailableUsers')
          .then(response => {
            commit('setAvailableUsers', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, event) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('Event', event)
          .then(response => {
            ctx.commit('addEvent', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, event) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('Event', event)
          .then(response => {
            ctx.commit('updateEvent', event)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`Event/${id}`)
          .then(response => {
            ctx.commit('deleteEvent', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
