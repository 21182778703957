import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: window.config.apiBaseUrl,
})

axiosIns.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')

    // Create a shallow copy of config to avoid directly modifying the parameter
    const updatedConfig = { ...config }

    if (token) {
      updatedConfig.headers = {
        ...config.headers, // Ensure other headers are preserved
        Authorization: `Bearer ${token}`,
      }
    }
    return updatedConfig
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
