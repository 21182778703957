import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import * as rules from 'vee-validate/dist/rules'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import axiosIns from './libs/axiosIns'

axiosIns.interceptors.response.use(
  response => response,
  error => {
    // Check if the response status is 401 or 403
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Clear any stored user/auth data in Vuex if necessary
      store.commit('auth/clearAuthData')

      // Redirect to the login page, but ignore the navigation error if already there
      router.push({ name: 'login' }).catch(() => {})
    } else {
      // Log other errors to the console for debugging
      console.error('API Error:', error)
    }

    // Reject the error so it can be handled locally if necessary
    return Promise.reject(error)
  },
)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// VeeValidate
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
localize('fr', fr)
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
