import Vue from 'vue'
import VueRouter from 'vue-router'
import jwtDecode from 'jwt-decode'
import store from '@/store' // eslint-disable-line

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        isPublic: true,
        layout: 'full',
      },
    },
    {
      path: '/planning',
      name: 'planning',
      component: () => import('@/views/calendar/Calendar.vue'),
      meta: {
        contentClass: 'planning',
      },
    },
    {
      path: '/jeunes',
      name: 'youngs',
      component: () => import('@/views/youngs/Youngs.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'youngs-view',
      },
    },
    {
      path: '/repondeur',
      name: 'history',
      component: () => import('@/views/history/History.vue'),
      meta: {
        contentClass: 'history',
      },
    },
    {
      path: '/blocklist',
      name: 'blocklist',
      component: () => import('@/views/blocklist/Blocklist.vue'),
      meta: {
        contentClass: 'blocklist',
      },
    },
    {
      path: '/statistiques',
      name: 'statistics',
      component: () => import('@/views/statistics/Statistics.vue'),
      meta: {
        contentClass: 'statistics',
      },
    },
    {
      path: '/utilisateurs',
      name: 'utilisateurs',
      component: () => import('@/views/users/Users.vue'),
      meta: {
        contentClass: 'utilisateurs',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/users/UserProfile.vue'),
      meta: {
        contentClass: 'profile',
      },
    },
    {
      path: '/signin-oidc',
      name: 'signin-oidc',
      component: () => import('@/views/SigninOidc.vue'),
      meta: {
        layout: 'full',
        skipPermission: true,
      },
    },
    {
      path: '/error-401',
      name: 'error-401',
      component: () => import('@/views/Error.vue'),
      props: {
        error: 'Utilisateur non authentifié',
        errorCode: 401,
      },
      meta: {
        skipPermission: true,
        isPublic: true,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/Error.vue'),
      props: {
        error: 'Page non trouvée',
        errorCode: 404,
      },
      meta: {
        skipPermission: true,
        isPublic: true,
        layout: 'full',
      },
    },
    {
      path: '/home',
      redirect: 'planning',
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const userRolesAuthorizedPaths = {
  'LeRefuge.Administrateur': ['/utilisateurs', '/statistiques', '/profile', '/'],
  'LeRefuge.Coordinateur': ['/planning', '/jeunes', '/repondeur', '/statistiques', '/utilisateurs', '/blocklist', '/profile', '/'],
  'LeRefuge.Ecoutant': ['/planning', '/jeunes', '/repondeur', '/blocklist', '/profile', '/'],
}

router.beforeEach(async (to, from, next) => {
  // Skip auth check if already on the login page
  if (to.name === 'login') {
    next()
  }

  const token = localStorage.getItem('token')

  if (token) {
    const decodedToken = jwtDecode(token)
    const userId = decodedToken.sub

    // Save user ID to Vuex store
    store.commit('auth/setUserId', userId)

    // Fetch user data
    try {
      await store.dispatch('users/getUserById', userId)
      const userRole = store.getters['users/getUserRole']
      if (userRolesAuthorizedPaths[userRole]?.includes(to.path) || to.meta.skipPermission) {
        next()
      } else {
        next({ path: userRolesAuthorizedPaths[userRole]?.at(0) || '/login' })
      }
      next() // Proceed with navigation after fetching user data
    } catch (error) {
      console.error('Failed to fetch user data:', error)
      next({ name: 'login' }) // Redirect to login on fetch error
    }
  } else {
    // No token, redirect to login if not already on it
    if (to.name !== 'login') {
      next({ name: 'login' })
    } else {
      next()
    }
    console.error('Not connected')
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
